<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container" :id="id">
          <div class="modal-header">
            <h2><slot name="header"> Project Settings </slot></h2>
            <a class="close" href="#" @mousedown.stop.prevent="$emit('close')"
              >&times;</a
            >
          </div>

          <div class="modal-body">
            <slot name="body"> default body </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer"> </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  props: { id: String },
  data: function () {
    return {};
  },
};
</script>
