<template>
  <div>
    <div class="admin-header-menu">
      <h1>Challenge</h1>
    </div>
    <div>{{ error }}</div>

    <div class="scenario-details">
      <form>
        <label>Title</label>
        <input name="title" :value="scenario.title" @input="save" />
        <label>Short description on front page</label>
        <textarea name="summary" :value="scenario.summary" @input="save"></textarea>
        <label>Description</label>
        <textarea name="description" :value="scenario.description" @input="save"></textarea>

        <label>Card color</label>
        <select v-model="scenario.color" @change="saveScenarioData">
          <option value="yellow">Yellow</option>
          <option value="green">Green</option>
          <option value="blue">Blue</option>
          <option value="red">Red</option>
        </select>

        <label>Budget per phase</label>
        <input name="budgetPerPhase" :value="scenario.budgetPerPhase" @input="save" />

        <label>Phase count</label>
        <input name="phases" :value="scenario.phases" @input="save" />

        <label>Start year</label>
        <input name="startYear" :value="scenario.startYear" @input="save" />

        <label>Scenario picture</label>

        <div v-if="scenario.images && scenario.images.length > 0">
          <cld-context
            cloudName="urban-nature-explorer"
            v-if="scenario.images"
            secure="true"
          >
            <cld-image
              :public-id="scenario.images[0]._id"
              height="200"
              width="423"
              crop="scale"
            />
          </cld-context>
          <div>
            <a class="link" @mouseup.prevent.stop="deletePicture"
              >Delete picture</a
            >
          </div>
        </div>
        <button
          id="upload"
          type="button"
          class="secondary"
          @mousedown="openUploadModal"
        >
          Upload
        </button>
      </form>
      <div></div>
    </div>
  </div>
</template>
<script>
import debounce from "debounce";
import { deleteCloudinaryImage } from "../store";
import { CldContext, CldImage } from "cloudinary-vue";

export default {
  components: { CldContext, CldImage },
  data: function () {
    return {
      scenario: {},
      targets: {},
      newIndicator: { baseline: {} },
      showNewIndicator: false,
      addedIndicator: null,
      openedIndicator: false,
      nbsTypeOptions: this.$store.state.currentProject.nbsTypesRaw.map((t) => {
        return { label: t.name, _id: t._id };
      }),
      save: function () {},
      indicatorsKey: 0,
      error: "",
      cloudName: process.env.VUE_APP_CLOUDINARY_CLOUD,
    };
  },
  created: function () {
    this.scenario = this.$store.state.scenario;
    this.scenario.nbsTypes = this.scenario.nbsTypes.filter((t) => {
      return t;
    });
    this.save = debounce(this.saveScenarioData, 1000);
  },
  methods: {
    saveScenarioData(event) {
      if (event && event.target.name) {
        this.scenario[event.target.name] = event.target.value
      }

      this.error = "";

      if (!this.scenario.nbsTypes) {
        const types = Object.keys(this.$store.state.currentProject.nbsTypes);
        this.scenario.nbsTypes = types;
      }

      this.$store
        .dispatch("saveScenario", this.scenario)
        .then(() => {
          this.$store.commit("setScenario", this.scenario);
          if (this.uploadWidget) this.uploadWidget.close();
        })
        .catch((e) => {
          this.error = "Unable to save scenario data: " + e;
        });
    },
    openUploadModal() {
      this.uploadWidget = window.cloudinary.createUploadWidget(
        {
          cloud_name: process.env.VUE_APP_CLOUDINARY_CLOUD,
          upload_preset: process.env.VUE_APP_CLOUDINARY_PRESET,
          showCompletedButton: false,
          sources: ["local", "url", "google_drive"],
          folder: this.$store.state.currentProject.slug,
          clientAllowedFormats: ["image"],
          maxFileSize: 5500000,
          maxImageWidth: 2000,
          maxImageHeight: 2000,
        },
        (error, result) => {
          if (!error && result && result.event === "success") {
            console.log("Done uploading..: ", result.info);
            this.url = result.info.url;
            this.publicId = result.info.public_id;
            if (!this.scenario.images) {
              this.scenario.images = [];
              this.scenario.images.push({
                url: result.info.url,
                _id: result.info.public_id,
              });
            } else {
              this.scenario.images.forEach((i) => {
                deleteCloudinaryImage(i._id);
              });
              this.scenario.images = [
                { url: result.info.url, _id: result.info.public_id },
              ];
            }
            this.saveScenarioData();
            window.cloudinary.closeUploadWidget();
          }
        }
      );
      this.uploadWidget.open();
    },
    deletePicture() {
      const images = this.scenario.images;
      images.forEach((i) => {
        deleteCloudinaryImage(i._id).then(() => {
          this.scenario.images = this.scenario.images.filter((img) => {
            img._id !== i._id;
          });
          this.saveScenarioData();
        });
      });
    },
  },
};
</script>
<style>
.clickable {
  cursor: pointer;
}
</style>
