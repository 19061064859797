<template>
  <div class="indicator-widget">
    <label>Name</label>
    <input v-model="indicator.name" @input="save" />
    <label>Unit</label>
    <input v-model="indicator.unit" @input="save" />
    <label>Unit displayed on chart axis</label>
    <input v-model="indicator.axisUnit" @input="save" />
    <label>Source</label>
    <input v-model="indicator.source" @input="save" />
    <label>Link</label>
    <input v-model="indicator.link" @input="save" />

    <label>Economic score</label>
    <input
      type="checkbox"
      v-model="indicator.economicScore"
      @change="saveIndicator"
    />

    <label>Calculation method</label>
    <select v-model="indicator.calculationMethod" @change="saveIndicator">
      <option
        value="normal"
        :selected="
          !indicator.calculationMethod ||
          indicator.calculationMethod == 'normal'
        "
      >
        Normal
      </option>
      <option
        value="average"
        :selected="indicator.calculationMethod == 'average'"
      >
        Average for area
      </option>
      <option
        value="percentageOfArea"
        :selected="indicator.calculationMethod == 'percentageOfArea'"
      >
        Percentage of area
      </option>
    </select>

    <indicator-values
      v-model="indicator.baseline"
      :indicator="indicator.name"
      valueKey="baseline"
      @input="saveIndicator"
    ></indicator-values>
  </div>
</template>
<script>
import debounce from "debounce";
import IndicatorValues from "./EditIndicatorValues";
export default {
  components: { IndicatorValues },
  name: "IndicatorWidget",
  props: {
    indicator: Object,
    indicatorKey: Number,
  },
  data: function () {
    return {
      showIndicatorModal: false,
    };
  },
  created: function () {
    this.save = debounce(this.saveIndicator, 1000);
  },
  methods: {
    saveIndicator() {
      this.$store
        .dispatch("updateProjectKey", {
          project: this.$store.state.currentProject,
          key: `indicators.${this.indicatorKey}`,
          method: "$set",
          data: this.indicator,
        })
        .then(() => {
          this.$emit("saved", this.indicator);
        });
    },
  },
};
</script>
