<template>
  <div>
    <form id="nbs-details">
      <h3 class="group-label">NBS Details</h3>
      <div v-if="isAdmin">
        <div>
          <label for="name">Name</label>
          <input name="name" :value="nbsType.name" @input="saveDelayed" />
        </div>
        <div>
          <label>Category</label>
          <select v-model="nbsType.category" @change="save">
            <option
              v-for="(data, c) in this.$store.state.nbsCategories"
              v-bind:key="c"
              :value="c"
            >
              {{ data.name }}
            </option>
          </select>

          <div
            v-if="
              nbsType.category &&
              this.$store.state.nbsCategories[nbsType.category]
            "
          >
            <label>Parent type</label>
            <select v-model="nbsType.subCategory" @change="save">
              <option :value="null">[none]</option>
              <option
                v-for="(c, index) in this.$store.state.nbsCategories[
                  nbsType.category || nbsType.nbsCategory
                ].subtypes"
                v-bind:key="index"
                :value="c._id"
              >
                {{ c.name }}
              </option>
            </select>
          </div>
        </div>
        <div>
          <label for="order">Order in menu</label>
          <input name="order" type="number" :value="nbsType.order" @input="saveDelayed" />
        </div>
        <div>
          <label for="description">Description</label>
          <textarea name="description" :value="nbsType.description" @input="saveDelayed"></textarea>
        </div>
        <div>
          <label>Unit:</label>
          <select v-model="nbsType.unit" @change="save">
            <option v-for="u in units" v-bind:key="u">{{ u }}</option>
          </select>
        </div>
      </div>
      <modal
        id="edit-appearance"
        v-if="showAppearanceModal"
        @close="showAppearanceModal = false"
      >
        <template v-slot:header>Edit NBS Appearance</template>
        <template v-slot:body>
          <div>
            <input id="fileUpload" type="file" hidden @change="uploadIcon" />
            <div class="select-icon" @mousedown="chooseFiles">
              <label v-if="!nbsType.object.icon">Select icon</label>

              <div v-if="nbsType.object.icon">
                <img :src="iconData()" id="icon-preview" /><br />
                <a href="#" @mouseup.prevent.stop="deleteIcon"
                  ><small>Delete icon</small></a
                >
              </div>
            </div>
            <label>Shape to draw</label>
            <select v-model="nbsType.object.type" @change="save">
              <option value="null" :selected="!nbsType.object.type">
                [select]
              </option>
              <option value="Point" :selected="nbsType.object.type == 'Point'">
                point
              </option>
              <option
                value="LineString"
                :selected="nbsType.object.type == 'LineString'"
              >
                line
              </option>
              <option
                value="Polygon"
                :selected="nbsType.object.type == 'Polygon'"
              >
                polygon
              </option>
            </select>
            <div v-if="nbsType.object.type == 'Point'">
              <label>Default number of generated points</label>
              <input
                v-model.number="nbsType.object.count"
                type="number"
                @change="save"
              />
            </div>
            <div v-if="nbsType.object.type == 'Circle'">
              <label>Circle radius</label>
              <input
                v-model.number="nbsType.object.options.radius"
                type="number"
                @change="save"
              />
            </div>
          </div>

          <div>
            <label>Color</label>
            <input
              v-model="nbsType.object.options.color"
              type="color"
              @change="save"
            />
          </div>
          <div>
            <label for="fill">Filled?</label>
            <div>
              <input
                type="checkbox"
                v-model="nbsType.object.options.fill"
                @change="save"
              />
              <span class="help"
                >Check this if the element has a color fill.</span
              >
            </div>
          </div>
        </template>
      </modal>

      <div v-if="nbsType.cost">
        <div>
          <label for="cost">Cost</label>
          <input name="cost__base" :value="nbsType.cost.base" @input="save" />

        </div>
        <div>
          <label for="cost-yearly">Maintenance cost</label>
          <input
            name="cost__maintenance__value"
            v-if="nbsType.cost.maintenance"
            :value="nbsType.cost.maintenance.value"
            @input="save"
          /> percent of price
        </div>
      </div>
      <div class="buttons">
        <button
          class="white edit"
          @mousedown.prevent="showAppearanceModal = true"
          v-if="isAdmin && !showAppearanceModal"
        >
          Edit appearance
        </button>
        <button
          type="button"
          class="white edit"
          @mousedown.prevent="showBehaviorModal = true"
          v-if="isAdmin && !showBehaviorModal"
        >
          Edit behavior
        </button>
        <button
          v-if="nbsType.object.predefined"
          class="white edit"
          @mousedown.prevent="goToPredefined"
        >
          Edit predefined areas
        </button>
        <button
          class="white edit"
          @mousedown="showImpactModal = true"
          v-if="isAdmin && !showImpactModal"
        >
          Edit impacts
        </button>
      </div>

      <modal v-if="showImpactModal" @close="showImpactModal = false">
        <template v-slot:header>Edit {{ nbsType.name }} impacts</template>
        <template v-slot:body>
          <label>Add impact</label>
          <select
            v-model="addImpactIndicator"
            @change="addImpact"
            v-bind:key="addImpactKey"
          >
            <option :value="null">[select]</option>
            <template v-for="i in indicators">
              <option
                v-if="!nbsType.impact || !nbsType.impact[i._id]"
                v-bind:key="i._id"
                :value="i._id"
              >
                {{ i.name }}
              </option>
            </template>
          </select>

          <div v-for="(indicator, index) in indicators" v-bind:key="index">
            <div
              class="impact"
              v-if="nbsType.impact && nbsType.impact[indicator._id]"
              :key="indicatorsKey"
              @mousedown="showImpactFor = indicator._id"
            >
              <label :for="'impact' + indicator"
                >{{ indicator.name }}
                <img v-if="showImpactFor != indicator._id"
                  src="/images/ui/chevron.png" />
              </label>
              <div v-if="showImpactFor == indicator._id">
              <template
                v-if="indicator.calculationMethod == 'percentageOfArea' ||
                indicator.calculationMethod == 'percentage'"
              >
                <select
                  v-model="nbsType.impact[indicator._id].type"
                  @change="save"
                >
                  <option
                    value="linear"
                    :selected="nbsType.impact[indicator._id].type == 'linear'"
                  >
                    linear
                  </option>
                  <option
                    value="percentagePerPercentage"
                    :selected="
                      nbsType.impact[indicator._id].type ==
                        'linearPerPercentage' ||
                      nbsType.impact[indicator._id].type ===
                        'percentagePerPercentage'
                    "
                  >
                    percentage per percentage
                  </option>
                  <option
                    value="percentagePerUnit"
                    :selected="
                      nbsType.impact[indicator._id].type == 'percentagePerUnit'
                    "
                  >
                    percentage per unit
                  </option>
                </select>

                <select
                  v-model="nbsType.impact[indicator._id].direction"
                  @change="save"
                >
                  <option
                    :value="null"
                    :selected="!nbsType.impact[indicator._id].direction"
                  >
                    do not affect
                  </option>
                  <option
                    value="increase"
                    :selected="
                      nbsType.impact[indicator._id].direction == 'increase'
                    "
                  >
                    increase
                  </option>
                </select>
                {{ indicator.unit }}.
                <br/>

                <span>
                  <span>Area covered by one unit: </span>
                  <input
                    type="number"
                    v-model.number="nbsType.impact[indicator._id].area_covered"
                    @change="save"
                  />
                </span>
              </template>

              <template
                v-if="indicator.calculationMethod != 'percentageOfArea' &&
                indicator.calculationMethod != 'percentage'
                "
              >
                <select
                  v-model="nbsType.impact[indicator._id].type"
                  @change="save"
                >
                  <option
                    value="linear"
                    :selected="nbsType.impact[indicator._id].type == 'linear'"
                  >
                    linear
                  </option>
                  <option
                    value="percentagePerPercentage"
                    :selected="
                      nbsType.impact[indicator._id].type ==
                        'linearPerPercentage' ||
                      nbsType.impact[indicator._id].type ===
                        'percentagePerPercentage'
                    "
                  >
                    percentage per percentage
                  </option>
                  <option
                    value="percentagePerUnit"
                    :selected="
                      nbsType.impact[indicator._id].type == 'percentagePerUnit'
                    "
                  >
                    percentage per unit
                  </option>
                </select>

                <select
                  v-model="nbsType.impact[indicator._id].direction"
                  @change="save"
                >
                  <option
                    value="decrease"
                    :selected="
                      nbsType.impact[indicator._id].direction == 'decrease'
                    "
                  >
                    decrease
                  </option>
                  <option
                    value="increase"
                    :selected="
                      nbsType.impact[indicator._id].direction == 'increase'
                    "
                  >
                    increase
                  </option>
                </select>

                <span>
                  <input
                    type="number"
                    v-model.number="nbsType.impact[indicator._id].area_covered"
                    @change="save"
                  />
                  <span> * </span>
                </span>

                <input
                  type="number"
                  v-model.number="nbsType.impact[indicator._id].value"
                  @change="save"
                />
                <span
                  class="indicator"
                  v-if="nbsType.impact[indicator._id].type === 'linear'"
                >
                  <span>{{ indicator.unit }} per </span>
                </span>
                <span
                  class="indicator"
                  v-if="
                    nbsType.impact[indicator._id].type ===
                      'percentagePerPercentage' ||
                    nbsType.impact[indicator._id].type === 'percentagePerUnit'
                  "
                >
                  <span>percentage per </span>
                </span>
                <span
                  class="indicator"
                  v-if="
                    nbsType.impact[indicator._id].type === 'linear' ||
                    nbsType.impact[indicator._id].type === 'percentagePerUnit'
                  "
                >
                  <input
                    type="number"
                    v-model.number="nbsType.impact[indicator._id].per_unit"
                    @change="save"
                  />
                  <span>{{ nbsType.unit }}</span>
                </span>
                <span
                  v-if="
                    nbsType.impact[indicator._id].type ===
                    'percentagePerPercentage'
                  "
                >
                  <input
                    type="number"
                    v-model.number="
                      nbsType.impact[indicator._id].percentage_covered
                    "
                    @change="save"
                  />
                  <span> % of area covered </span>
                </span>
              </template>
            </div>
            </div>
          </div>

          <h3 v-if="nbsType.satisfaction">Stakeholder satisfaction</h3>
          <div class="satisfaction">
            <div v-for="(v, k) in nbsType.satisfaction" v-bind:key="k">
              <label>{{ k }}: </label>
              <input v-model="nbsType.satisfaction[k]" @change="save" />
            </div>
          </div>
        </template>
      </modal>

      <modal
        id="edit-behavior"
        v-if="showBehaviorModal"
        @close="showBehaviorModal = false"
      >
        <template v-slot:header>Edit NBS Behavior</template>
        <template v-slot:body>
          <div>
            <label>Fixed?</label>
            <div>
              <input
                type="checkbox"
                :disabled="nbsType.object.type == 'Point'"
                v-model="nbsType.object.fixed"
                @change="save"
              />
              Check this if the element has a fixed shape.
            </div>
          </div>

          <div>
            <label>Building?</label>
            <div>
              <input
                type="checkbox"
                v-model="nbsType.behavior.buildings"
                @change="save"
              />
              Check this if you want to add this on buildings.
            </div>
          </div>
          <div>
            <label>Predefined?</label>
            <div>
              <input
                type="checkbox"
                v-model="nbsType.object.predefined"
                @change="save"
              />

              Check this if you only want to allow a set of predefined areas for
              this NBS type.
            </div>
          </div>

          <div class="buttons">
            <button
              v-if="nbsType.object.predefined"
              class="white edit"
              @mousedown.prevent="goToPredefined"
            >
              Edit predefined areas
            </button>
          </div>

          <div>
            <label>Singleton?</label>
            <div>
              <input
                type="checkbox"
                v-model="nbsType.object.singleton"
                v-if="nbsType.object.predefined"
                @change="save"
              />
              Check this if you only want to allow all predefined areas at once
              for this NBS type.
            </div>
          </div>

          <div>
            <label>Is placing on top of other NBS allowed?</label>
            <div>
              <input
                type="checkbox"
                :checked="nbsType.behavior.otherTypes.length > 1"
                @change="addOtherTypes"
              />Yes
            </div>
            <div v-if="showOtherTypes">
              <label>Select NBS types</label>
              <div><small>Start typing to search for types</small></div>
              <v-select
                :multiple="true"
                :reduce="(type) => type._id"
                v-model="nbsType.behavior.otherTypes"
                :options="otherNbsTypes"
                @change="save"
              ></v-select>
            </div></div></template
      ></modal>
    </form>
    <div>
      <modal
        id="delete-confirm"
        v-if="showDeleteConfirm"
        @close="showDeleteConfirm = false"
      >
        <template v-slot:header>Delete NBS type</template>
        <template v-slot:body
          >Are you sure you want to completely remove this NBS type from the
          project?</template
        >
        <template v-slot:footer>
          <button type="button" @mousedown.prevent="deleteType">Yes</button>
          <button
            type="button"
            class="white"
            @mousedown.prevent="showDeleteConfirm = false"
          >
            No
          </button></template
        >
      </modal>

      <button
        class="danger"
        type="button"
        v-if="isAdmin"
        @mouseup.prevent.stop="showDeleteConfirm = true"
      >
        Delete NBS type
      </button>
    </div>
  </div>
</template>
<script>
import { debounce } from "debounce";
import vSelect from "vue-select";
import Modal from "./Modal";
import bson from "bson";
import {currentUser} from '../store';

export default {
  name: "NbsTypeForm",
  components: { vSelect, Modal },
  props: {
    typeKey: Number,
    nbsType: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data: function () {
    const types = this.$store.state.currentProject.nbsTypesRaw;
    const otherNbsTypes = [];
    if (!this.nbsType.behavior) this.nbsType.behavior = { otherTypes: [] };
    if (!this.nbsType.behavior.otherTypes) {
      this.nbsType.behavior.otherTypes = [];
    }

    types.forEach((t) => {
      const compatible =
        t._id == this.nbsType._id ||
        (t.behavior &&
          t.behavior.otherTypes &&
          t.behavior.otherTypes.indexOf(this.nbsType._id) > -1) ||
        (this.nbsType.behavior &&
          this.nbsType.behavior.otherTypes &&
          this.nbsType.behavior.otherTypes.indexOf(t._id) > -1);
      const object = { _id: t._id, label: t.name };
      if (
        !otherNbsTypes.find((type) => {
          return t.id == type._id;
        })
      ) {
        otherNbsTypes.push(object);
      }
      if (compatible) {
        if (this.nbsType.behavior.otherTypes.indexOf(t._id) == -1) {
          this.nbsType.behavior.otherTypes.push(object._id);
        }
      }
    });
    const categories = [];
    return {
      units: ["", "pcs", "m2", "m"],
      directions: ["increase", "decrease"],
      indicatorBehaviors: ["linear", "linearPerPercentage"],
      indicatorsKey: 0,
      indicators: this.$store.state.currentProject.indicators,
      otherNbsTypes: otherNbsTypes,
      categories: categories,
      addImpactIndicator: null,
      addImpactKey: 1,
      showOtherTypes: this.nbsType.behavior.otherTypes,
      showImpactModal: false,
      showAppearanceModal: false,
      showDeleteConfirm: false,
      showBehaviorModal: false,
      showImpactFor: false,
      isAdmin: currentUser().customData.admin
    };
  },
  created: function () {
    this.saveDelayed = debounce(this.save, 1000);
    this.nbsType.cost = this.nbsType.cost || {};
    this.nbsType.cost.maintenance = this.nbsType.cost.maintenance || {};
    this.nbsType.energySavings = this.nbsType.energySavings || {};
    this.nbsType.impact = this.nbsType.impact || {};
  },
  methods: {
    resetWidget() {},

    addImpact() {
      if (this.addImpactIndicator) {
        if (!this.nbsType.impact) this.nbsType.impact = {};
        this.nbsType.impact[this.addImpactIndicator] = { value: null };
        this.addImpactIndicator = null;
        this.indicatorsKey += 1;
        this.addImpactKey += 1;
      }
    },
    addOtherTypes() {
      if (
        this.nbsType.behavior.otherTypes &&
        this.nbsType.behavior.otherTypes.length > 1
      ) {
        this.nbsType.behavior.otherTypes = [this.nbsType._id];
        this.save();
        this.showOtherTypes = false;
      } else {
        this.showOtherTypes = true;
      }
    },
    deleteType() {
      this.$store
        .dispatch("deleteNbsType", {
          project: this.$store.state.currentProject,
          id: this.nbsType._id,
        })
        .then(() => {
          this.$emit("deleted", this.nbsType);
        })
        .catch((e) => {
          console.log(e);
          this.$emit("error", "Unable to delete nbs type");
        });
    },
    iconData() {
      function toBase64(arr) {
        return btoa(
          arr.reduce((data, byte) => data + String.fromCharCode(byte), "")
        );
      }
      return this.nbsType.object &&
        this.nbsType.object.icon &&
        this.nbsType.object.icon.data
        ? `data:image/png;base64,${toBase64(
            this.nbsType.object.icon.data.buffer
          )}`
        : null;
    },
    uploadIcon(event) {
      // var output = new Image
      // document.getElementById('icon-preview');
      const fr = new FileReader();
      fr.readAsArrayBuffer(event.target.files[0]);
      fr.onload = () => {
        const blob = new Blob([fr.result]);
        // console.log(output)
        // URL.revokeObjectURL(output.src) // free memory

        // // // make <canvas> of the same size
        // let canvas = document.createElement('canvas');
        // canvas.width = output.clientWidth;
        // canvas.height = output.clientHeight;

        // let context = canvas.getContext('2d');

        // // copy image to it (this method allows to cut image)
        // context.drawImage(output, 0, 0);
        // // we can context.rotate(), and do many other things on canvas
        // console.log(canvas)
        // // toBlob is async operation, callback is called when done
        // canvas.toBlob((blob) => {
        function toBuffer(ab) {
          const buf = Buffer.alloc(ab.byteLength);
          const view = new Uint8Array(ab);
          for (let i = 0; i < buf.length; ++i) {
            buf[i] = view[i];
          }
          return buf;
        }
        blob.arrayBuffer().then((t) => {
          this.nbsType.object.icon = {
            type: blob.type,
            data: new bson.Binary(toBuffer(t)),
          };
          this.save();
        });
        // }, 'image/png');
      };
    },
    deleteIcon() {
      this.nbsType.object.icon = null;
      this.save();
    },
    save(event) {
      if (event.target.name) {
        const name = event.target.name
        if(name.indexOf('__') !== -1) {
          const names = name.split('__')
          if (names.length === 2) {
            this.nbsType[names[0]][names[1]] = event.target.value
          }
          if (names.length === 3) {
            this.nbsType[names[0]][names[1]][names[2]] = event.target.value
          }
        }
        else {
          this.nbsType[event.target.name] = event.target.value
        }
      }

      //console.log(event.target.name)
      const original =
        this.$store.state.currentProject.nbsTypesRaw[this.typeKey];

      if (this.nbsType.object.type == "Point") {
        if (!this.nbsType.object.count) {
          this.nbsType.object.count = 1;
        }
        this.nbsType.object.fixed = true;
      } else if (
        this.nbsType.object.type != "Point" &&
        original.object.type == "Point"
      ) {
        this.nbsType.object.fixed = false;
        this.nbsType.object.count = false;
      }

      for (const indicator in this.nbsType.impact) {
        if (this.nbsType.impact.hasOwnProperty(indicator)) {
          const num = parseFloat(this.nbsType.impact[indicator].value);
          const isInt = num === parseInt(this.nbsType.impact[indicator].value);

          if (this.nbsType.impact[indicator].value) {
            this.nbsType.impact[indicator].value = isInt ? num.toFixed(2) : num;
          }

          this.nbsType.impact[indicator].area_covered = parseFloat(
            this.nbsType.impact[indicator].area_covered || 1
          ).toFixed(2);

          this.nbsType.impact[indicator].percentage_covered = parseFloat(
            this.nbsType.impact[indicator].percentage_covered || 1
          );

          if (this.nbsType.object.predefined) {
            this.nbsType.object.fixed = true;
          }
        }
      }

      //				this.compatibleOtherTypes.push(value)
      if (this.nbsType.behavior.otherTypes.indexOf(this.nbsType._id) == -1) {
        this.nbsType.behavior.otherTypes.push(this.nbsType._id);
      }

      if (this.nbsType.object.params) {
        this.nbsType.object.params[0] = parseFloat(
          this.nbsType.object.params[0]
        ).toFixed(2);
        this.nbsType.object.params[1] = parseFloat(
          this.nbsType.object.params[1]
        ).toFixed(2);
      }

      let data = this.nbsType
      let typeKey = this.typeKey

      if (event.target.name == 'cost__base') {
        data = event.target.value
        typeKey = typeKey + '.cost.base'
      }
      if (event.target.name == 'cost__maintenance__value') {
        data = event.target.value
        typeKey = typeKey + '.cost.maintenance.value'
      }

      if (!this.invalid) {
        this.$store
          .dispatch("saveNbsType", {
            project: this.$store.state.currentProject,
            data: data,
            id: typeKey,
          })
          .then(() => {
            this.$emit("input", this.nbsType);
          })
          .catch((e) => {
            console.log(e);
            this.$emit("error", "Unable to save nbs type");
          });
      }
    },

    goToPredefined() {
      this.$router.push({
        path: "/Admin/Predefined",
      });
    },
    chooseFiles() {
      document.getElementById("fileUpload").click();
    },
  },
};
</script>

<style lang="scss">
#nbs-details {
  h3 {
    font-size: $text-normal;
    color: $color-greenmiddle;
  }
}

.select-icon {
  width: 146px;
  height: 134px;
  left: 0px;
  top: 1px;

  border: 1.5px dashed #eb621c;
  border-radius: 10px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;

  h3 {
    width: 50px;
    height: 44px;
  }
  :hover {
    cursor: pointer;
  }
}

.danger {
  float: right;
  padding: 20px;
  line-height: 12px;
  margin-top: 30px;
  background: red;
  font-size: 10px;
}

#appearance-settings {
  display: grid;
  grid-template-columns: 45% 45%;
  grid-template-rows: auto;
  gap: 5%;
  margin-bottom: 30px;
}
</style>
