<template>
  <div class="actions">
    <a
      class="edit area-selector-action"
      v-if="edit"
      @mousedown.stop.prevent="$emit('edit')"
      ><img src="/images/ui/edit.png" alt="Edit"
    /></a>
    <a
      v-if="clone"
      class="duplicate area-selector-action"
      @mousedown.stop.prevent="$emit('duplicate')"
      ><img src="/images/ui/duplicate.png" alt="Duplicate"
    /></a>
    <a
      v-if="trash"
      class="delete area-selector-action"
      @mousedown.stop.prevent="$emit('delete')"
      ><img src="/images/ui/trash.png" alt="Delete"
    /></a>
  </div>
</template>
<script>
export default {
  props: {
    edit: { type: Boolean, default: true },
    trash: { type: Boolean, default: true },
    clone: { type: Boolean, default: true },
  },
  data: function () {
    return {};
  },
};
</script>
<style lang="scss">
.actions {
  display: flex;
}
.area-selector-action {
  display: none;
}
a:hover .area-selector-action {
  display: block;
  margin-right: 30px;
}
</style>
