<template>
  <div id="admin-scenario" class="admin">
    <div id="scenario-menu">
      <a
        :class="'button h2 ' + activeClass('location')"
        @mousedown.prevent.stop="route = 'location'"
        >Location</a
      >
      <a
        :class="'button h2 ' + activeClass('settings')"
        @mousedown.prevent.stop="route = 'settings'"
        >Challenge</a
      >
      <a
        :class="'button h2 ' + activeClass('indicators')"
        @mousedown.prevent.stop="route = 'indicators'"
        >Indicators</a
      >
      <a
        :class="'button h2 ' + activeClass('nbsTypes')"
        @mousedown.prevent.stop="route = 'nbsTypes'"
        >NBS</a
      >
      <a
        :class="'button h2 ' + activeClass('preview')"
        @mousedown.prevent.stop="startOver"
        >Preview</a
      >
    </div>
    <div id="scenario-content">
      <div class="error">{{ error }}</div>
      <ScenarioSettings
        v-if="route == 'settings'"
        @save="saveScenarioData"
        @error="handleError"
      />
      <NbsTypes
        v-if="route == 'nbsTypes'"
        @save="saveScenarioData"
        @error="handleError"
      />
      <Indicators
        v-if="route == 'indicators'"
        @save="saveScenarioData"
        @error="handleError"
      />
      <ScenarioLocation
        v-if="!route || route == 'location'"
        @save="saveScenarioData"
        @error="handleError"
      />
    </div>
  </div>
</template>
<script>
import ScenarioSettings from "../../components/ScenarioSettings";
import ScenarioLocation from "../../components/ScenarioLocation";
import NbsTypes from "../../components/NbsTypes";
import Indicators from "../../components/Indicators";

export default {
  components: { ScenarioLocation, ScenarioSettings, NbsTypes, Indicators },
  data: function () {
    return {
      route: "location",
      error: "",
    };
  },
  created: function () {
    if (!this.$store.state.currentProject) {
      this.$router.push({ path: "/Admin/Projects" });
    }
    if (!this.$store.state.scenario) {
      this.$router.push({ path: "/Admin/ProjectDashboard" });
    }
  },
  mounted() {
    const cloudinaryScript = document.createElement("script");
    cloudinaryScript.setAttribute(
      "src",
      "https://upload-widget.cloudinary.com/global/all.js"
    );
    document.head.appendChild(cloudinaryScript);
  },
  methods: {
    startOver() {
      this.$store.commit("setSolution", null);
      this.$store.commit("setScenario", null);
      try {
        this.$router.push({ path: "/SelectScenario" });
      } catch (e) {
        console.log(e);
      }
    },
    activeClass(route) {
      return this.route == route ? "active" : "inactive";
    },
    saveScenarioData(scenario) {
      this.error = "";
      this.$store
        .dispatch("saveScenario", scenario)
        .then((s) => {
          this.$store.commit("setScenario", s);
        })
        .catch((e) => {
          this.error = "Unable to save scenario data: " + e;
        });
    },
    handleError(error) {
      this.error = error;
    },
  },
};
</script>
<style lang="scss">
@import "../../styles/admin.scss";

#scenario-menu {
  margin-right: 110px;
  .button {
    display: block;
    border: solid 2px $color-base;
    border-radius: $border-radius;
    width: 190px;
    height: 20px;
    padding: 20px 0;
    text-align: center;
    margin-bottom: 20px;
    color: $color-base;
    cursor: pointer;
    &.active {
      color: $color-base;
      border: none;
      background: $color-lightgreen;
    }
  }
}
#scenario-content {
  flex-basis: 70%;
}
#admin-scenario {
  display: flex;
}
</style>
