<template>
  <div>
    <div class="admin-header-menu">
      <h1>NBS Types</h1>
    </div>
    <div id="info">{{ info }}</div>
    <div id="scenario-nbs">
      <div id="nbs-options" v-bind:key="nbsOptionsKey">
        <div
          v-for="(parentTypes, label) in groupedNbsOptions"
          v-bind:key="label"
          class="draggable-group"
        >
          <h3 class="group-label">{{ label }}</h3>

          <div
            v-for="(types, parentType) in parentTypes"
            v-bind:key="parentType"
            class="nbs-type"
          >
            <div class="header">
              <a
                @mousedown.prevent="toggleParentNbsWidget(parentType)"
                class="nbs-label parent"
                >{{ parentType }}</a
              >

              <a
                @mousedown.prevent="toggleParentNbsWidget(parentType)"
                class="nbs-label"
                v-if="!openParentTypes.includes(parentType)"
              >
                <img src="/images/ui/chevron.png" />
                <img
                  v-if="openParentTypes.includes(parentType)"
                  src="/images/ui/chevron-up.png"
                />
              </a>
            </div>
            <div v-if="openParentTypes.includes(parentType)">
              <draggable
                class="list-group"
                :list="groupedNbsOptions[label][parentType]"
                group="nbsTypes"
                @change="nbsDragged"
              >
                <div
                  :class="
                    'list-group-item nbs-subtype ' +
                    (nbsType && nbsType._id == type.nbsType._id
                      ? 'active'
                      : 'inactive')
                  "
                  v-for="type in types"
                  v-bind:key="type.index"
                >
                  <a
                    @mousedown.prevent="selectNbs(type.index, type.nbsType)"
                    class="nbs-label"
                    >{{ type.nbsType.name }}</a
                  >
                  <EditButtons
                    :edit="false"
                    :clone="false"
                    @duplicate="duplicate(project)"
                    @delete="removeType(type.nbsType)"
                  />
                </div>
              </draggable>
            </div>
          </div>
        </div>
        <br />
        <a class="add" href="#" @mousedown.prevent.stop="addForm = true"
          >Add NBS type</a
        >
        <form class="add-nbs" v-if="addForm">
          <label>Select existing type</label>
          <v-select
            v-model="addedNbs"
            :options="nbsTypeOptions"
            :reduce="(t) => t._id"
            @input="addNbsToScenario"
          >
          </v-select>
        </form>
      </div>

      <div class="nbs-details">
        <modal v-if="createForm" @close="createForm = false">
          <template v-slot:header>Create NBS type</template>
          <template v-slot:body>
            <div class="error" v-if="modalError">{{ modalError }}</div>
            <form id="add-nbs" @submit.prevent>
              <label>Name:</label>
              <input v-model="newNbs" />
              <label>Clone from: (optional)</label>
              <select v-model="cloneNbs">
                <option value="">[select]</option>
                <option
                  v-for="(nbsType, type) in nbsTypes"
                  v-bind:key="type"
                  :value="type"
                >
                  {{ nbsType.name }}
                </option>
              </select>
              <div class="buttons">
                <button @mousedown.prevent="createType">Create</button>
              </div>
            </form>
          </template>
        </modal>
        <NbsTypeForm
          v-if="nbsType"
          :nbsType="nbsType"
          :typeKey="typeKey"
          @input="refreshTypes"
          @error="handleError"
          @deleted="handleDeleted"
        ></NbsTypeForm>
      </div>
    </div>
  </div>
</template>
<script>
import NbsTypeForm from "./NbsTypeForm";
import EditButtons from "./EditButtons";
import Modal from "./Modal";
import vSelect from "vue-select";
import draggable from "vuedraggable";

export default {
  components: { EditButtons, NbsTypeForm, Modal, draggable, vSelect },
  data: function () {
    return {
      addForm: false,
      newNbs: "",
      addedNbs: null,
      cloneNbs: "",
      scenario: this.$store.state.scenario,
      scenarioNbs: this.$store.state.scenario.nbsTypes,
      selected: null,
      indicators: {},
      nbsTypes: [],
      nbsType: null,
      typeKey: null,
      nbsOptionsKey: 0,
      openParentTypes: [],
      info: "",
      error: "",
      createForm: false,
      modalError: "",
    };
  },
  created: function () {
    this.$store.dispatch("loadNbsCategories").then((categories) => {
      this.categories = categories;
      this.$store.dispatch("reloadProject").then((project) => {
        this.nbsTypes = project.nbsTypesRaw;
        if (!this.scenarioNbs) {
          this.scenarioNbs = Object.keys(project.nbsTypes);
          this.nbsOptionsKey += 1;
        }
      });
    });
  },
  computed: {
    nbsTypeOptions() {
      return this.nbsTypes
        .filter((t) => {
          return this.scenario.nbsTypes.indexOf(t._id) === -1;
        })
        .map((t) => {
          return { label: t.name, _id: t._id };
        })
        .concat([{ label: "+ Create new type", _id: "__create" }]);
    },
    groupedNbsOptions() {
      const options = { "Nature-Based Solutions": {}, Amenities: {} };
      for (let k = 0; k < this.nbsTypes.length; k++) {
        if (
          this.nbsTypes[k].object &&
          this.scenarioNbs &&
          this.scenarioNbs.indexOf(this.nbsTypes[k]._id) > -1
        ) {
          const type = this.nbsTypes[k];
          const key = type.amenity ? "Amenities" : "Nature-Based Solutions";

          let c = { name: "Other" };

          if (type.category || type.nbsCategory) {
            const cid = type.category || type.nbsCategory;
            c = this.categories[cid];
            if (type.subCategory) {
              let subc = this.categories[cid].subtypes.find((s) => {
                return s._id == type.subCategory;
              });
              if (subc) c = subc
            }
          }
          if (!options[key][c.name]) {
            options[key][c.name] = [];
          }
          options[key][c.name].push({ index: k, nbsType: type });
        }
      }
      return options;
    },
  },
  methods: {
    toggleParentNbsWidget(parentType) {
      if (this.openParentTypes.includes(parentType)) {
        this.openParentTypes = this.openParentTypes.filter(
          (t) => t !== parentType
        );
      } else {
        this.openParentTypes.push(parentType);
      }
    },

    createType() {
      if (this.newNbs.length > 3) {
        const id =
          this.newNbs.toLowerCase().replace(/\W/g, "_") +
          Math.floor(Math.random() * (99999 - 10000) + 10000);
        let data = {};
        if (this.cloneNbs) {
          data = JSON.parse(JSON.stringify(this.nbsTypes[this.cloneNbs]));
          data.name = this.newNbs;
          data._id = id;
        } else {
          data = {
            _id: id,
            name: this.newNbs,
            object: { options: {} },
            icon: this.newNbs[0],
          };
        }
        this.$store
          .dispatch("createNbsType", {
            project: this.$store.state.currentProject,
            data: data,
          })
          .then(() => {
            this.info = "Nbs type created.";
            this.$store
              .dispatch("loadProjects", {
                _id: { $oid: this.$store.state.currentProject._id.toString() },
              })
              .then((workshops) => {
                this.nbsTypes = workshops[0].nbsTypesRaw;
              });
          });
      } else {
        this.modalError = "Name is required";
      }
    },
    addNbsToScenario() {
      if (this.addedNbs == "__create") {
        this.createForm = true;
      } else {
        this.scenarioNbs.push(this.addedNbs);
        this.scenario.nbsTypes = this.scenarioNbs;
        this.$emit("save", this.scenario);

        const nbsType =
          this.$store.state.currentProject.nbsTypes[this.addedNbs];
        const category = nbsType.category || nbsType.nbsCategory;
        if (!this.openParentTypes.includes(category)) {
          this.openParentTypes.push(category);
        }
        this.nbsOptionsKey += 1;
        this.addForm = false;
      }
    },
    nbsDragged() {
      // console.log(this.groupedNbsOptions)
    },
    selectNbs(typeKey, type) {
      this.$store.commit("setCurrentNbs", type);
      this.typeKey = typeKey;
      this.nbsType = type;
    },
    removeType(nbsType) {
      this.scenario.nbsTypes = this.scenario.nbsTypes.filter(
        (t) => t !== nbsType._id
      );
      this.$emit("save", this.scenario);
      this.nbsType = null;
      this.typeKey = null;
      this.scenarioNbs = this.scenario.nbsTypes;
    },
    refreshTypes(objectType) {
      if (
        objectType.behavior &&
        objectType.behavior.otherTypes &&
        objectType.behavior.otherTypes.length > 1
      ) {
        this.nbsTypes.forEach((t, i) => {
          if (
            t._id !== objectType._id &&
            t.behavior.otherTypes &&
            t.behavior.otherTypes.indexOf(objectType._id) !== -1 &&
            objectType.behavior.otherTypes.indexOf(t._id) == -1
          ) {
            t.behavior.otherTypes.splice(
              t.behavior.otherTypes.indexOf(objectType._id)
            );
            this.$store
              .dispatch("saveNbsType", {
                project: this.$store.state.currentProject,
                data: t,
                id: i,
              })
              .then(() => {
                this.info = `Removed NBS combination for ${objectType.name} and ${t.name}`;
              })
              .catch(() => {
                this.error = "Unable to remove NBS combination";
              });
          }
        });
      }

      this.$store.dispatch("reloadProject").then((workshop) => {
        this.nbsTypes = workshop.nbsTypesRaw;
      });
    },
    handleError(error) {
      this.error = error;
    },
    handleDeleted(nbsType) {
      this.info = `Deleted ${nbsType.name}`;
      this.refreshTypes();
    },
  },
};
</script>
<style lang="scss" scoped>
#scenario-nbs {
  display: flex;

  h3 {
    color: $color-greenmiddle;
  }
  .nbs-subtype .nbs-label {
    display: block;
    width: 100%;
  }
}
.nbs-details .modal-container {
  input {
    width: 257px;
  }
  select {
    width: 300px;
  }
  button {
    text-align: center;
  }
}

.nbs-label {
  cursor: pointer;
}

.custom-select {
  display: inline;
  width: inherit;
}
.impact {
  display: flex;
}
.impact > * {
  display: flex;
  flex-basis: 20%;
}
.impact > label {
  min-width: 250px;
}
.impact .indicator {
  flex-basis: 40%;
}
.impact > * > * {
  flex-basis: 20%;
}
.satisfaction {
  display: flex;
  flex-direction: row;
}
</style>
