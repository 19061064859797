<template>
  <div id="admin-scenario-location">
    <div class="admin-header-menu">
      <h1>Scenario Location</h1>
      <div class="actions" v-if="overrided">
        <a class="edit-circle" @mousedown.prevent="drawBoundary" href="#"> </a>
      </div>
    </div>
    <div>{{ error }}</div>
    <div>
      <div id="location">
        <button
          class="primary"
          type="button"
          v-if="overrided === false"
          @mousedown="overrideSite"
        >
          Override project site
        </button>
        <EditSite
          ref="editSite"
          v-if="site && overrided"
          :site="site"
          @save="updateSite"
          @error="setError"
        />
      </div>
    </div>
  </div>
</template>
<script>
import EditSite from "./EditSite";
const cloneDeep = require("lodash.clonedeep");

export default {
  components: {
    EditSite,
  },
  data: function () {
    return {
      scenario: {},
      site: null,
      overrided: null,
      save: function () {},
      error: "",
    };
  },
  created: function () {
    this.scenario = this.$store.state.scenario;
    if (typeof this.$store.state.scenario.site == "string") {
      this.$store
        .dispatch("loadSite", {
          _id: { $oid: this.$store.state.scenario.site },
        })
        .then((site) => {
          this.site = site;
          this.overrided =
            this.site._id !== this.$store.state.currentProject.site._id;
        });
    } else if (typeof this.$store.state.scenario.site === "object") {
      this.site = this.$store.state.scenario.site;
      this.overrided =
        this.site._id !== this.$store.state.currentProject.site._id;
    } else {
      this.overrided = false;
    }
  },
  methods: {
    overrideSite() {
      const newSite = cloneDeep(this.site);
      delete newSite._id;
      this.$store
        .dispatch("createSite", newSite)
        .then((site) => {
          this.scenario.site = site._id.toString();
          this.site = site;
          this.$store
            .dispatch("saveScenario", this.scenario)
            .then(() => {
              this.$store.commit("setScenario", this.scenario);
              this.overrided = true;
            })
            .catch(() => {
              this.error = "Unable to save scenario data";
            });
        })
        .catch(() => {
          this.error = "Unable to override scenario location";
        });
    },
    updateSite(site) {
      this.site = site;
    },
    drawBoundary() {
      this.$refs.editSite.startDrawing();
    },
    goToPredefined() {
      this.$router.push({
        path: "/Admin/Predefined",
      });
    },
    setError(error) {
      this.error = error;
    },
  },
};
</script>
<style>
.clickable {
  cursor: pointer;
}
.admin-header-menu {
  margin-bottom: 40px;
}
</style>
