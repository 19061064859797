<template>
  <div class="indicator-values">
    <modal v-if="showEditor" @close="showEditor = false">
      <template v-slot:header>Edit {{ valueKey }} for {{ indicator }}</template>
      <template v-slot:body>
        <div class="error">{{ error }}</div>
        <table class="table float-left block-center">
          <tr>
            <th>Year</th>
            <th>value</th>
            <th>actions</th>
          </tr>
          <tr v-for="(val, year) in value" v-bind:key="year">
            <th>{{ year }}</th>
            <td>
              <input
                type="number"
                step="0.01"
                v-model.number="value[year]"
                @input="save"
              />
            </td>
            <button @mousedown="removeValue(year)">Remove</button>
          </tr>

          <tr>
            <td>
              <input type="number" name="year" v-model.number="newValueYear" />
            </td>
            <td>
              <input
                type="number"
                name="value"
                step="0.01"
                v-model.number="newValueValue"
              />
            </td>
            <td>
              <button name="add" @mousedown="addNewIndicatorValue">
                Add
              </button>
            </td>
          </tr>
        </table>
      </template>
    </modal>
    <div id="buttons">
      <button
        class="edit white"
        @mousedown.prevent="toggleEditor"
        v-if="!showEditor"
      >
        Edit {{ valueKey }}
      </button>
    </div>
  </div>
</template>
<script>
import Modal from "./Modal";

export default {
  props: { indicator: String, value: Object, valueKey: String },
  components: { Modal },
  methods: {
    addNewIndicatorValue() {
      if (this.newValueYear && this.newValueValue) {
        this.value[this.newValueYear] = this.newValueValue;
        this.newValueYear = "";
        this.newValueValue = "";
        this.$emit("input", this.value);
      } else {
        this.error = "Year and value is required";
      }
    },

    removeValue(year) {
      delete this.value[year];
      this.$emit("input", this.value);
    },

    save() {
      this.$emit("input", this.value);
    },
    toggleEditor() {
      this.showEditor = true;
    },
  },
  data: function () {
    return {
      showEditor: false,
      newValueValue: "",
      newValueYear: "",
      error: "",
    };
  },
};
</script>
<style lang="scss">
.indicator-values {
  .modal-container {
    width: 600px;
  }
  table {
    margin: 0 auto;
    input {
      width: 120px;
    }
  }
}
</style>
