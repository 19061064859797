<template>
  <div>
    <modal
      id="delete-indicator"
      v-if="indicatorDeleteConfirm"
      @close="indicatorDeleteConfirm = false"
    >
      <template v-slot:header>Delete indicator?</template>
      <template v-slot:body
        >Are you sure you want to delete indicator
        <strong>{{ indicator.name }}</strong> from all scenarios?</template
      >
      <template v-slot:footer
        ><button @mousedown="deleteIndicator">Delete</button>
        <button class="danger" @mousedown="deleteIndicatorFromProject">
          Delete from all scenarios
        </button>
        <button class="white" @mousedown="indicatorDeleteConfirm = false">
          Cancel
        </button></template
      >
    </modal>

    <div class="admin-header-menu">
      <h1>Indicators</h1>
    </div>
    <div class="indicator-form">
    <div class="draggable-group">
      <draggable
        class="list-group"
        :list="scenarioIndicators"
        group="nbsTypes"
        @change="indicatorDragged"
      >
        <div
          :class="
            'list-group-item ' +
            (openedIndicator == indicator._id ? 'active' : 'inactive')
          "
          v-for="indicator in scenarioIndicators"
          v-bind:key="indicator._id"
        >
          <a
            href="#"
            @mousedown.prevent="openIndicator(indicator._id)"
            class="nbs-label"
          >
            <span>{{
              indicators.find((i) => i._id == indicator._id).name
            }}</span>
            <edit-buttons
              :edit="false"
              :clone="false"
              @duplicate="duplicate(project)"
              @delete="indicatorDeleteConfirmModal(indicator)"
            />
          </a>
        </div>
      </draggable>
      <a class="add" href="#" @mousedown.stop.prevent="showAddIndicator"
        >Add indicator</a
      >
    </div>
      <div class="indicator-details">
        <div
          :class="'target ' + indicator"
          v-for="(indicator, index) in indicators"
          v-bind:key="index"
        >
          <div v-if="openedIndicator == indicator._id">
            <label>Key indicator?</label>
            <input
              type="checkbox"
              :checked="scenario.keyIndicators.indexOf(indicator._id) > -1"
              @change="setKeyIndicator(indicator._id)"
            />

            <indicator-widget
              :indicator="indicator"
              :indicatorKey="index"
              @saved="updateIndicators"
            ></indicator-widget>

            <indicator-targets
              :indicatorKey="index"
              :indicator="scenario.indicators[indicator._id]"
              @input="saveScenarioIndicator"
            />
          </div>
        </div>

        <div class="newIndicator" v-if="showNewIndicator">
          <select v-model="addedIndicator" @change="addNewIndicator">
            <option :value="null">[Select indicator]</option>
            <template v-for="(indicator, index) in indicators">
              <option
                v-if="!(indicator._id in scenario.indicators)"
                :value="indicator._id"
                v-bind:key="index"
              >
                {{ indicator.name }}
              </option></template
            >
            <option value="__new">+ New indicator</option>
          </select>
          <div v-if="addedIndicator == '__new'">
            <label>Name</label>
            <input v-model="newIndicator.name" />
            <button type="button" @mousedown.prevent.stop="createIndicator">
              Create
            </button>
          </div>
        </div>
      </div>
      </div>
  </div>
</template>
<script>
import IndicatorWidget from "./IndicatorWidget";
import IndicatorTargets from "./IndicatorTargets";
import Modal from "./Modal";
import EditButtons from "./EditButtons";
import draggable from "vuedraggable";
import debounce from "debounce";

export default {
  components: { IndicatorTargets, IndicatorWidget, EditButtons, Modal, draggable },
  data: function () {
    return {
      indicatorDeleteConfirm: false,
      showTargetsModal: false,
      scenario: {},
      newIndicator: { baseline: {} },
      scenarioIndicators: [],
      showNewIndicator: false,
      addedIndicator: null,
      openedIndicator: false,
      save: function () {},
      indicatorsKey: 0,
      indicators: this.$store.state.currentProject.indicators || [],
      error: "",
    };
  },
  methods: {
    updateIndicators() {
      this.$store.dispatch("reloadProject").then(() => {
        this.indicators = this.$store.state.currentProject.indicators || [];
      });
    },
    openIndicator(indicator) {
      if (this.openedIndicator == indicator) {
        this.openedIndicator = false;
      } else {
        this.openedIndicator = indicator;
      }
    },
    showAddIndicator() {
      this.showNewIndicator = true;
    },
    addIndicatorToScenario(indicator) {
      this.scenario.indicators[indicator] = {
        _id: indicator
      };
      this.scenarioIndicators = Object.values(this.scenario.indicators || {});
      this.saveScenarioData();
      this.showNewIndicator = false;
    },
    addNewIndicator() {
      if (this.addedIndicator && this.addedIndicator !== "__new") {
        this.addIndicatorToScenario(this.addedIndicator);
      }
    },
    createIndicator() {
      this.newIndicator._id =
        this.newIndicator.name.toLowerCase().replace(/\W/g, "_") +
        Math.floor(Math.random() * (99999 - 10000) + 10000);

      this.$store
        .dispatch("updateProjectKey", {
          project: this.$store.state.currentProject,
          key: "indicators",
          method: "$push",
          data: this.newIndicator,
        })
        .then(() => {
          this.targets[this.newIndicator._id] = {};
          this.indicators.push(this.newIndicator);
          this.addIndicatorToScenario(this.newIndicator._id);
          this.newIndicator = { baseline: {} };
          this.showNewIndicator = false;
          this.updateIndicators();
        })
        .catch((e) => {
          this.$emit("error", "Undable to save project data: " + e);
        });
    },
    indicatorDeleteConfirmModal(indicator, key) {
      this.indicatorKey = key;
      this.indicator = indicator;
      this.indicatorDeleteConfirm = true;
    },
    deleteIndicator() {
      delete this.scenario.indicators[this.indicator._id];
      this.scenarioIndicators = Object.values(this.scenario.indicators || {});

      this.$emit("save", this.scenario);
      this.indicatorDeleteConfirm = false;
    },
    deleteIndicatorFromProject() {
      this.deleteIndicator();

      this.$store
        .dispatch("updateProjectKey", {
          project: this.$store.state.currentProject,
          key: "indicators",
          method: "$pull",
          data: { _id: this.indicator._id },
        })
        .then(() => {
          this.deleteIndicator();
          this.updateIndicators();
        })
        .catch((e) => {
          this.$emit("error", "Unable to save project data: " + e);
        });
    },
    indicatorDragged() {},
    setKeyIndicator(indicator) {
      const index = this.scenario.keyIndicators.indexOf(indicator);
      if (index == -1) {
        if (this.scenario.keyIndicators.length < 2) {
          this.scenario.keyIndicators.push(indicator);
        } else {
          this.error = "Only two key indicators are allowed.";
          return;
        }
      } else {
        this.scenario.keyIndicators.splice(index);
      }
      this.saveScenarioData();
    },

    saveScenarioIndicator(indicator) {
      this.scenario.indicators[indicator._id] = indicator
      this.saveScenarioData();
    },

    saveScenarioData() {
      this.error = "";
      this.$emit("save", this.scenario);
      // this.$store.dispatch('saveScenario', this.scenario).then(() => {
      // 	this.$store.commit('setScenario', this.scenario)
      // }).catch(() => {
      // 	this.error = 'Undable to save challenge data'
      // })
    },
  },
  created() {
    this.scenario = this.$store.state.scenario;
    this.scenario.indicators = this.scenario.indicators || {};

    this.scenarioIndicators = Object.values(this.scenario.indicators || {});
    this.indicators = this.$store.state.currentProject.indicators || [];

    if (!this.scenario.keyIndicators) this.scenario.keyIndicators = [];
    this.save = debounce(this.saveScenarioData, 1000);
  },
};
</script>
<style>
div.indicator-form {
  display: flex;
}
.indicator-details {
  flex: 1;
}

label {
  display: block;
}

.list-group-item a {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.indicator-form .area-selector-action {
  margin: 0 5px;
}
</style>
