<template>
	<div class="edit-inidcator-targets">
		<div class="buttons">
	      <button
	        class="edit white"
	        @mousedown.prevent.stop="showModal = true"
	      >
	        Targets
	      </button>
	    </div>
	    <modal v-if="showModal" @close="showModal = false">
	      <template v-slot:header>Edit targets</template>
	      <template v-slot:body>
	        <label>Target year:</label>
	        <input
	        	name="year"
	          type="number"
	          :value="year"
	          @input="save"
	        />
	        <label>Target value:</label>
	        <input
	        	name="value"
	          type="number"
	          :value="value"
	          @input="save"
	        />

	        <label>Target description</label>
	        <textarea
	          name="description"
	          :value="description"
	          @input="save"
	        ></textarea>
	      </template>
	    </modal>
	 </div>
</template>
<script>
	import debounce from "debounce";
	import Modal from "./Modal";

	export default {
		components: { Modal },
	  name: "IndicatorTargets",
	  props: {
	    indicator: Object,
	    indicatorKey: Number,
	  },
	  data: function () {
	    return {
	      showModal: false,
	      description: this.indicator.targetDescription || '',
	      year: typeof(this.indicator.targets) == 'object' ?
	      	Object.keys(this.indicator.targets)[0] : '',
	      value: typeof(this.indicator.targets) == 'object' ?
	      	Object.values(this.indicator.targets)[0] : ''
	    };
	  },
	  created: function () {
	    this.save = debounce(this.saveIndicator, 1000);
	  },
	  methods: {
	    saveIndicator(event) {
	    	if (
	    		(event.target.name === 'year' && !this.value) ||
	    		(event.target.name === 'value' && !this.year)
	    	) {
	    		return
	    	}

	    	let key, value;

	    	switch (event.target.name) {
		    	case "year":
		    		this.indicator.targets = { [event.target.value.toString()]: this.value }
		    	break;
			    case "value":
		    		this.indicator.targets = { [this.year.toString()]: event.target.value }
					break;
					case "description":
						this.indicator.targetDescription = event.target.value
		    	break;
		    }

	      // this.$store
	      //   .dispatch("updateProjectKey", {
	      //     project: this.$store.state.currentProject,
	      //     key: `indicators.${this.indicatorKey}`,
	      //     method: "$set",
	      //     data: this.indicator,
	      //   })
	      //   .then(() => {
	      this.$emit("input", this.indicator);
	      //   });
      }
    }
	};
</script>